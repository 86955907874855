/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.0/dist/jquery.min.js
 * - /npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js
 * - /npm/simple-jekyll-search@1.10.0/dest/simple-jekyll-search.min.js
 * - /npm/lazysizes@5.3.2/lazysizes.min.js
 * - /npm/dayjs@1.11.7/dayjs.min.js
 * - /npm/dayjs@1.11.7/locale/es.min.js
 * - /npm/dayjs@1.11.7/plugin/relativeTime.min.js
 * - /npm/dayjs@1.11.7/plugin/localizedFormat.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
